<template>
  <div>
    <b-table
    style="width: 200px;"
    striped
    small
    bordered
    selectable
    selected-variant="primary"
    select-mode="single"
    responsive="sm"
    head-row-variant="primary"
    :fields="fields"
    :items="commission"
    @row-dblclicked="editComission"
    >

    </b-table>
    <b-modal
    id="editCommission"
    title="Изменить"
    size="sm"
    hide-footer
    no-enforce-focus
    :no-close-on-backdrop="true"
    >
    <b-row>
      <b-col
      cols="12"
      >
      <b-input v-model="editPercent.percent" type="number"></b-input>
      </b-col>
    </b-row>
    <b-row class="mb-1 mt-2">
      <b-col class="d-flex justify-content-end">
        <b-button :disabled="submitButtonDisabled" @click="update()" variant="primary">Изменить</b-button>
        <b-button @click="closeModal()" class="ml-1" variant="danger">Отмена</b-button>
      </b-col>
    </b-row>
    </b-modal>
  </div>
</template>

<script>
import { resizeable } from '@/utils/TableFunctions/resizeable.js'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default {
  components: {
    ToastificationContent
  },
  data(){
    return {
      fields: [
        { key: 'percent', sortable: true, label: 'Комиссия', thStyle: {width: '100px'}},
      ],
      submitButtonDisabled: false,
      commission: [],
      editPercent: {
        percent: null
      }
    }
  },
  mounted() {
    resizeable()
    this.getData()
  },
  methods: {
    getData() {
      this.commission.length = 0
      this.$http
      .get('order-commissions/get-percent')
      .then(res => {
        this.commission.push(res.data)
        this.editPercent.percent = res.data.percent
      })
      .catch(err => {
        console.log(err);
      })
    },
    closeModal(){
      this.$bvModal.hide('editCommission')
    },
    update() {
      this.submitButtonDisabled = true
      this.$http
      .post('order-commissions/set-percent', this.editPercent)
      .then( res => {
        this.closeModal()
        this.getData()
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Успех!',
            icon: 'CheckIcon',
            variant: 'success',
            text: `${res.data.message}`,
          },
        })
      })
      .finally(() =>{
        this.submitButtonDisabled = false
      })
    },
    editComission() {
      this.$bvModal.show('editCommission')
      this.editPercent.percent = this.commission[0].percent
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-context.scss';
@media (max-height: 700px) {
  .my-card{
    overflow: auto; 
    height: 530px;
    }
}
table{
  outline: none; 
  table-layout: fixed; 
  -webkit-user-select: none;
  overflow: hidden;
  position: relative;
}
tr, td, th{
    padding: 0;
}
td, th{ 
    width: 100px; 
    white-space: nowrap; 
    overflow: hidden;  
    text-align: left; 
    box-sizing: border-box; 
    min-width: 60px;
}
</style> 